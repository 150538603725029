<template>
  <div class="order-list">
    <div v-if="Object.keys(order_agrupado_data).length">
      <div
        class="grupo"
        v-for="(grupo, data) in order_agrupado_data"
        v-bind:key="data"
      >
        <div class="cabecalho">
          <b>
            {{ $filters.toDateBRL(data, 'D/M/YY') }} -
            {{ $filters.capitalize($filters.toDayOfWeek(data, 'D/M/YY')) }}
          </b>
        </div>
        <order
          v-for="order in grupo"
          v-bind:key="order.PED_CODIGO"
          :order="order"
        />
      </div>
    </div>
    <div v-else>
      {{ $t('erro.semDados') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Order from './Order'
import moment from '../../../helpers/date/moment'

export default {
  name: 'OrderList',
  components: { Order },
  computed: {
    ...mapGetters('orders', {
      orders: 'getOrders',
    }),
    order_agrupado_data: function () {
      let orders = this.orders
      let grupos = {}
      orders.forEach((order) => {
        if (!order.hasOwnProperty('DATA')) return
        let data = order.DATA

        if (!grupos.hasOwnProperty(data)) grupos[data] = []
        grupos[data].push(order)
      })

      let entries = Object.entries(grupos)
      const sortedArray = entries.sort(([data_a, val_a], [data_b, val_b]) => {
        const a = moment(data_a, 'DD/MM/YY')
        const b = moment(data_b, 'DD/MM/YY')
        return b.diff(a)
      })
      grupos = Object.fromEntries(sortedArray)
      return grupos
    },
  },
  methods: {
    ...mapActions('orders', ['loadOrders']),
    ...mapActions('create_protocol', ['loadSubjects', 'loadReasons', 'loadDataConsumer', 'loadSubReasons']),
    loadData: async function () {
      await Promise.all([        
        this.loadReasons(),       
        this.loadSubjects({ cto_id: 5, ass_mot_ope: 'N' }),
        this.loadOrders(),
        this.loadDataConsumer(),
        this.loadSubReasons()
      ]).catch((error) => {
        this.ShowToast({
          variant: 'error',
          message: error
        })
      })
    },
  },
  created: function () {
    this.loadData()
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.order-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 var(--space);
  @media (max-width: 576px) {
    padding: 0;
  }
  .grupo {
    width: 100%;
    margin-bottom: var(--space__1-5x);

    .cabecalho {
      margin: var(--space) 0;
      @media (max-width: 576px) {
        margin: var(--space__0-25x) 0;
      }
    }
  }
}
</style>
