<template>
  <customer-view-template>
    <stepper />
    <div class="bank-data">
      <form action="" @submit.prevent="formSubmit">
        <div class="form-inline">
          <h3>{{ $t('dass.bankData') }}</h3>          
          <div class="dass-form-row">
            <dass-input-text
              :required="true"
              type="tel"
              id="dass-input-agencia"
              :disabled="this.disabledFields"
              placeholder="Ex. 0000-7 (4 à 5 dígitos)"              
              :label="$t('etapas.comprador.agencia') + ':'"
              v-model="form_data.cli_agencia"
              @blur="validateAgencia()"
            />
            <dass-input-text
              :required="true"
              type="tel"
              id="dass-input-conta"
              :disabled="this.disabledFields"
              :label="$t('etapas.comprador.conta') + ':'"
              placeholder="Ex. 0000-7 (4 à 12 dígitos)"
             v-model="this.form_data.cli_conta"
              @blur="validateConta()"
            />
            <dass-input-text
              :required="true"
              id="dass-input-pix"  
              :disabled="this.disabledFields"            
              type="text"                        
              :label="$t('etapas.comprador.chavePix') + ':'"
              :modelValue="this.pixKeyFormated"
              v-model="this.form_data.cli_chavepix"
              placeholder="CPF,CNPJ,Email ou Telefone"
              @update:modelValue="maskPix"
              @blur="validatePix"                                    
            />
          </div>
          <div class="dass-form-row">
            <dass-select
                name="select-bank"
                :label="$t('etapas.comprador.codigoBanco') + ':'"
                :required="true"
                :options="optionsBanks"                
                id="dass-select-bank"
                :disabled="this.disabledFields"
                @change="
                  (val) => {
                    if (typeof val == 'object') return
                    form_data.ban_codigo = val
                    handleBankCode(val)                  
                  }
                "
              >
              </dass-select>  
               <span v-if="this.bankName" class="dass-bank-name">{{ this.bankName }}</span> 
          </div>
        </div>        
        <div class="actions">
          <dass-button
          v-if="this.disabledFields"          
            :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"
            :id="`dass-button-tertiary-close-${this.brandName}`" 
            variant="tertiary"
            type="button"
            @click="this.disabledFields = false"
          >
            <dass-icon token="icon-content-create" size="30"></dass-icon>
            {{ $t('dass.changeData') }}
          </dass-button>
          <dass-button
          :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"           
          :id="`dass-button-tertiary-close-${this.brandName}`"
          variant="tertiary"
            type="button"
            @click="cancel"
          >
            <dass-icon token="icon-content-clear" size="30"></dass-icon>
            {{ $t('dass.cancelar') }}
          </dass-button>

          <dass-button  :class="this.isSafari ? `dass-button-primary-${this.brandName} button` : 'button'" variant="primary" type="submit">
            {{ $t('dass.proximo') }}
            <dass-icon
              token="icon-navigation-arrow_forward_ios"
              size="30"
            ></dass-icon>
          </dass-button>
        </div>
      </form>
    </div>
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import Stepper from '../components/organisms/customer_view_template/Stepper'

import { VMoney } from 'v-money'
import { moneyMask } from '../helpers/globalfilters/defaultMoneyMask'
import { mapGetters, mapActions } from 'vuex'
import { cpfValidator } from '../helpers/functions/cpfValidator'
import { pixValidator } from '../helpers/functions/pixValidator'
import { agenciaValidator } from '../helpers/functions/agenciaValidator'
import { contaValidator } from '../helpers/functions/contaValidator'

export default {
  name: 'BankData',
  props: {
    ecommerce: Boolean,
  },
  components: {
    CustomerViewTemplate,
    Stepper,
  },
  data: () => ({
    moneyMask,
    isSafari: false,
    brandName: '',
    bankName: '',
    banks: [],
    currentMask: '',    
    pixKeyFormated: '',     
    disabled_fields: [],
    disabledFields: false, 
    form_data: {
      ban_codigo: '',
      cli_agencia: '',
      cli_conta: '',
      cli_chavepix: ''
    },
    MOTIVOS: {
      A_PECA_QUE_RECEBI_NAO_FOI_A_QUE_COMPREI: 19,
      O_PRODUTO_CHEGOU_COM_DEFEITO: 20,
    },
    optionsBanks: []
  }),
  directives: { money: VMoney },
  computed: {
    ...mapGetters('create_protocol', {
      dados_item: 'getDadosItens',
      getItensToAddBankData: 'getItensToAddBankData',
      getDataConsumer: 'getDataConsumer',
      bankData: 'getBankData'
    }),
    ...mapGetters('session', ['getUsuEmail', 'getUser', 'getUserAddress']),
    ...mapGetters('brand', { marca: 'getBrand' }),
    ...mapGetters('orders', {  orders: 'getOrders', }), 
  }, 
  methods: {
    ...mapActions('create_protocol', [
      'setCurrentStep',
      'createBankData',
      'setPictureList',
      'loadBankData'
    ]),
    ...mapActions('toasts', {
      showToast: 'showToast',
    }),
    ...mapActions('session', ['handlerUser']), 
    isEveryNumberInString(val) {          
      return /^[0-9]+$/.test(val)     
    },
    maskPix() {
      
      const val = this.form_data.cli_chavepix.replace(/\D/g, '') || ''
      if(this.isEveryNumberInString(val)) {
        if(val.length == 11 || val.length == 10) {          
          if(val.length === 11 && cpfValidator(val)) {                       
            this.pixKeyFormated = val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')            
          } else if(val.length == 11) {            
            this.pixKeyFormated = val.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')            
          } else {           
            this.pixKeyFormated = val.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')            
          }
        } else if(val.length > 11 && val.length <= 14) {              
          this.pixKeyFormated = val.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }       
      }       
      return 
             
    }, 
    validatePix() {   
      if(!pixValidator(this.form_data.cli_chavepix)) {       
        this.form_data.cli_chavepix = ''
        this.pixKeyFormated = '' 
        document.querySelector('#dass-input-pix').value = ''    
        return this.$ShowAlert({
          variant: 'error',
          message: this.$t('dass.invalidPix') + '<br>' + 'Formatos aceitos: CPF, CNPJ, email ou telefone.',
          hideDelay: 2000
        })
      }
    },
    validateAgencia() {
      if(!agenciaValidator(this.form_data.cli_agencia)) {
        this.form_data.cli_agencia = '' 
        return this.$ShowAlert({
          variant: 'error',
          message: this.$t('dass.invalidAgency') + '<br>' + 'Ex. 0000-7 (4 à 5 dígitos)',
          hideDelay: 2000
        })
      }
    },
    validateConta() {
      if(!contaValidator(this.form_data.cli_conta)) {
        this.form_data.cli_conta = '' 
        return this.$ShowAlert({
          variant: 'error',
          message: this.$t('dass.invalidAccount') + '<br>' + 'Ex. 0000-7 (4 à 12 dígitos)',
          hideDelay: 2000
        })
      }
    },
    formSubmit: function () {
      this.validatePix()
      let dados = {       
        ban_codigo: this.form_data.ban_codigo,
        cli_agencia: this.form_data.cli_agencia,
        cli_conta: this.form_data.cli_conta,
        cli_chavepix: this.form_data.cli_chavepix
      }
      this.createBankData(dados)
      this.next()
    },           
    next: function () {
      let path = '/produtos-adquiridos-fora-da-loja-online/confirmacao'
      this.$router.push({
        path,
      })
    },
    cancel: function () {
      this.$router.go(-1)
    }, 
    formatOptionsBank() {      
      this.banks.sort((a, b) => {
        if (a.BAN_CODIGO < b.BAN_CODIGO) return -1
        if (a.BAN_CODIGO > b.BAN_CODIGO) return 1
        return 0
      })
      this.optionsBanks = this.banks.filter(item => 
        item.BAN_NOME.trim() !== 'AJUSTE EXERCICIOS ANTERIORES' && item.BAN_NOME.trim() !== '(Alterado para 33)'
        && item.BAN_NOME.trim() !== 'AJUSTE' && item.BAN_NOME.trim() !== 'OUTROS'
         &&  item.BAN_NOME.trim() !== 'Nao usar' && item.BAN_NOME.trim().toUpperCase() !== 'MUTUOS'
         && item.BAN_NOME.trim() !== 'DEVOLUCAO' && item.BAN_NOME.trim() !== 'TRANSPORTADORAS' && 
         !item.BAN_NOME.includes('LIQUIDACAO') && item.BAN_NOME.trim() !== 'FILA DO BRASIL'
      )
     
      this.optionsBanks = this.optionsBanks.map(item => {
              
        return {
          value: item.BAN_CODIGO,
          text:  item.BAN_CODIGO  + ' - ' + item.BAN_NOME 
        }
       
      })      
      
      this.optionsBanks.unshift({
        value: '',
        text: this.$t('dass.selectAnItem'),
        disabled: true,
        selected: true
      })
    },
    removeBanks(bank) {
      
    },
    handleBankCode(codeBank) {      
      const bankName = this.banks.filter((item) => {
        return item.BAN_CODIGO == codeBank
      })
      this.bankName = bankName[0].BAN_NOME
    },
    setSelectBank() {      
      const codeBank = this.form_data.ban_codigo
      this.optionsBanks = this.optionsBanks.map((item) => {
        if (item.value == codeBank) {
          item.selected = true
        }
        return {
          ...item
        }
      })
    },
    getBankDataInConsumer() {
      const consumerData = this.getDataConsumer
       
      if(consumerData.length > 0 && consumerData[0].BAN_CODIGO && consumerData[0].CLI_AGENCIA
       && consumerData[0].CLI_CONTA && consumerData[0].CLI_CHAVEPIX) {
        const item = consumerData[0]
        this.form_data.ban_codigo = item.BAN_CODIGO
        this.form_data.cli_agencia = item.CLI_AGENCIA
        this.form_data.cli_conta = item.CLI_CONTA
        this.form_data.cli_chavepix = item.CLI_CHAVEPIX
      } else if(Object.entries(this.bankData).length > 0 && Object.values(this.bankData).every((value) => value !== '' && value !== null)) {     
        this.form_data = this.bankData
      } else {
        return
      }

      if (Object.values(this.form_data).every((value) => value !== '' && value !== null)) {
        this.disabledFields = true
        this.maskPix()     
        this.handleBankCode(this.form_data.ban_codigo)
        this.setSelectBank()  
      } 
    }   
  },

  created: async function () {
    this.isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)
    this.brandName = this.marca.name.replace(/\s/g, '').toLowerCase()   
    this.setCurrentStep(4)    
    if (
      this.dados_item.length &&
       this.dados_item[0].hasOwnProperty('produtoNome')
    )
      return

    if (this.dados_item.hasOwnProperty('produtoNome')) return

    this.showToast({
      variant: 'warning',
      message: this.$t('erro.carregar'),
    })
    return this.cancel()
  },
  async mounted() {  
    const response = await this.loadBankData()
    if(response.length > 0 && this.banks.length == 0) {
      this.banks = response
      this.formatOptionsBank()
    }
    this.getBankDataInConsumer()
  }  
}
</script>

<style lang="scss">
.bank-data {
  text-align: left;
  form {
    display: flex;
    flex-wrap: wrap;

    > div,
    > div.form-inline {
      padding: var(--space);
      padding-top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      h3 {
        width: 100%;
        margin: 0;
        margin-bottom: var(--space__0-5x);
      }
      @media (max-width: 576px) {
        padding: 0;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      > div {
        @media (max-width: 576px) {
          width: 100%;
          margin: 0;
        }
        @media (min-width: 576px) {
          margin-right: var(--space);
        }
        > div {
          margin-top: var(--space__0-75x);
        }
        label {
          font-weight: bold !important;
        }
        div.dass-input-text--input-wrapper {
          margin-top: 0;
        }
      }
    }
    .dass-form-row{
      max-width: 100%;      
      display: flex;
      column-gap: var(--space);
      flex-wrap: wrap;
      align-items: center;      
      @media (max-width: 740px) {
        flex-direction: column;
       align-items: unset;
       span {
         display: flex;
         flex-direction: column;         
         align-items: center;
       }
      }
      .dass-input-text {
        min-width: 250px !important;
      }
      #dass-select-bank {
        min-width: 250px !important;
        margin-top: var(--space__0-5x);
      }
    }

    .actions {
      > button:first-of-type {
        margin-right: var(--space);
      }
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      align-items: center;      
    }    
  }
  #dass-button-tertiary-close-umbro {
    margin-right: var(--space);
    color: var(--theme-color) !important;    
  }
}
</style>
