import { toMoney, fromMoney } from './money'
import { toDateBRL, toHourBRL, toDayOfWeek, validateDate } from './date'
import { capitalize } from 'vue'

const globalFiltersMethods = {
  toMoney,
  fromMoney,
  toDateBRL,
  toHourBRL,
  toDayOfWeek,
  capitalize,
  validateDate
}

const globalFiltersPlugin = (app, options = {}) => {
  const methods = globalFiltersMethods

  app.$filters = methods
  app.config.globalProperties.$filters = methods
  app.provide('$filters', globalFiltersPlugin)
}

export { globalFiltersMethods }
export default globalFiltersPlugin
