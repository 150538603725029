export const SET_SUBJECTS = 'SET_SUBJECTS'
export const SET_REASONS = 'SET_REASONS'
export const FILL_DADOS_GERAIS = 'FILL_DADOS_GERAIS'
export const FILL_DADOS_ITENS = 'FILL_DADOS_ITENS'
export const FILL_BANK_DATA = 'FILL_BANK_DATA'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_PICTURE_LIST = 'SET_PICTURE_LIST'
export const SET_SUB_REASONS = 'SET_SUB_REASONS'
export const SET_ALL_SUB_REASONS = 'SET_ALL_SUB_REASONS'
export const SET_ITENS_TO_ADD_BANK_DATA = 'SET_ITENS_TO_ADD_BANK_DATA'
export const SET_DATA_CONSUMERS = 'SET_DATA_CONSUMERS'

