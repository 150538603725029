import Protocols from '@/services/protocols'
import store from '../..'
import * as types from './mutation-types'
import { processDetail } from '../protocols/actions'

export const loadProtocol = async ({ commit }, filters) => {
  const brand = store.getters['brand/getBrand'] || {}
  const mar_id = brand.id || ''
  const default_filters = {
    mar_id,
    prt_id: '',
  }

  const request_body = Object.assign({}, default_filters, filters)
  const response = await Protocols.getProtocols(request_body)
  let protocols = response.calls || []
  protocols = processDetail(protocols)

  let protocol = protocols[0]

  commit(types.FILL_PROTOCOL, { protocol })
  return protocol
}

export const loadProtocolHistory = async ({ commit }, field = { prt_id }) => {
  const default_fields = {
    prt_id: '',
  }
  const request_body = Object.assign({}, default_fields, field)
  const response = await Protocols.getProtocolHistory(request_body)
  const history = response.history
  history.sort(sortHistoryByDate)
  commit(types.FILL_HISTORY, { history })
  return history
}

function sortHistoryByDate(a, b) {
  if (a.PHS_DATA < b.PHS_DATA) {
    return -1
  }
  if (a.PHS_DATA > b.PHS_DATA) {
    return 1
  }
  return 0
}
