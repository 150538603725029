import { clientAPI } from '../configs/configs'
import * as env from '../configs/configs'
import { MOD_SUBMODULO } from '../helpers/constants/authentication'

const urlBase = env.urlGeneral + '/authentication'
export default class Login {
  static async doLogout() {
    return new Promise((res, rej) => {
      try {
        clientAPI
          .get(`${urlBase}/logout`)
          .then((response) => {
            res(response)
          })
          .catch((error) => {
            rej(error)
          })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async checkToken() {
    return new Promise((res, rej) => {
      try {
        clientAPI
          .get(`${urlBase}/valid`)
          .then((response) => {
            res(response)
          })
          .catch((error) => {
            rej(error)
          })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async getProfileDataUsingCookie() {
    return new Promise((res, rej) => {
      try {
        clientAPI
          .post(`${urlBase}/profile`, { ...MOD_SUBMODULO })
          .then((response) => {
            res(response)
          })
          .catch((error) => {
            rej(error)
          })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async sendEmailCode(fields) {
    const default_body = {
      origin: MOD_SUBMODULO.mod_sub_modulo,
      brand: 0,
      email: '',
    }
    const requestBody = Object.assign({}, default_body, fields)

    return new Promise((res, rej) => {
      try {
        clientAPI
          .post(`${urlBase}/fastaccess/code`, requestBody)
          .then((response) => {
            res(response.data)
          })
          .catch((error) => {
            rej(error)
          })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async verifyCode(fields) {
    const default_body = {
      origin: MOD_SUBMODULO.mod_sub_modulo,
      brand: 0,
      email: '',
      tokenCode: '',
    }
    const requestBody = Object.assign({}, default_body, fields)

    return new Promise((res, rej) => {
      try {
        clientAPI
          .post(`${urlBase}/fastaccess/login`, requestBody)
          .then((response) => {
            res(response.data)
          })
          .catch((error) => {
            rej(error)
          })
      } catch (err) {
        rej(err)
      }
    })
  }
}

export const checkToken = Login.checkToken
export const getProfileDataUsingCookie = Login.getProfileDataUsingCookie
