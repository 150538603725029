import { ref, onMounted } from 'vue'

export function useMobile() {
  const isMobile = ref(false)

  onMounted(() => {
    const userAgent = navigator.userAgent
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i    
    isMobile.value = mobileRegex.test(userAgent)    
  })

  return { isMobile }
}