import { clientAPI } from '../configs/configs'
import * as env from '../configs/configs'

const current_url = window.location.href
let urlBase = env.urlGeneral + '/upload'
if (current_url.includes('localhost')) {
  urlBase = 'http://localhost:3070'
}
export default class Orders {
  static async uploadFile(fields) {
    const default_fields = {
      uploadFile: 'FILE',
      prt_id: '',
    }
    const request_body = Object.assign({}, default_fields, fields)
    let form_data = new FormData()
    Object.entries(request_body).forEach(([field, value]) => {
      form_data.append(field, value)
    })

    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return new Promise((res, rej) => {
      
      clientAPI
        .post(`${urlBase}/protocols/upload/file`, form_data, headers)
        .then((response) => {
          res(response)
        })
        .catch((error) => {
          rej(error)
        })
      
    })
  }
}
