<template>
  <div v-if="historic.length > 0 && historic[0]" class="c-history">
    <div
      v-for="history in historic"
      v-bind:key="history.PHS_ID"
      class="historic"
    >
      <div class="titulo">
        <span> Status: </span>
        <span :class="`color-sit-${protocol.SIT_ID} status`">
          {{ $t(`situacoes.${protocol.SIT_ID}`) }}
        </span>
      </div>
      <div class="linha">
        <div>
          <div>
            <b>{{ $t('protocolo.protocolo') }}:</b>
          </div>
          <div>{{ protocol.PRT_ID }}</div>
        </div>
        <div>
          <div>
            <b>{{ $t('protocolo.dataAtendimento') }}:</b>
          </div>
          <div>
            {{ $filters.toDateBRL(protocol.PRT_DTCRIACAO) }} -
            {{ $filters.toHourBRL(protocol.PRT_DTCRIACAO) }}
          </div>
        </div>
        <div>
          <div>
            <b>{{ $t('protocolo.tipo') }}:</b>
          </div>
          <div>{{ protocol.MOT_NOME }}</div>
        </div>
      </div>
      <div class="cinza linha">
        <div>
          <div>
            <b>{{ $t('protocolo.ondeComprou') }}:</b>
          </div>
          <div>{{ protocol.PRT_LOJA }}</div>
        </div>
        <div>
          <div>
            <b>{{ $t('protocolo.NF') }}:</b>
          </div>
          <div>{{ protocol.PRT_NFISCAL }}</div>
        </div>
        <div class="produto">
          <div>
            <b>{{ $t('protocolo.produto') }}:</b>
          </div>
          <div>
            {{ protocol.PRT_PRODUTO }}
          </div>
        </div>
      </div>
      <div class="linha">
        <div>
          <div>
            <b>{{ $t('dass.email') }}:</b>
          </div>
          <div>{{ protocol.USU_EMAIL }}</div>
        </div>
        <div>
          <div>
            <b>{{ $t('etapas.comprador.telefone') }}:</b>
          </div>
          <div>{{ protocol.CON_FONE_CEL }}</div>
        </div>
      </div>
      <div class="linha">
        <div>
          <div>
            <b>{{ $t('etapas.produto.valorCompra') }}:</b>
          </div>
          <div class="valor">{{ $filters.toMoney(protocol.PRT_VALOR) }}</div>
        </div>
        <div class="observacoes">
          <div>
            <b>{{ $t('etapas.produto.observacao') }}:</b>
          </div>
          <div>{{ protocol.PRT_DESCRICAO }}</div>
        </div>
      </div>

      <hr />

      <div class="subtitulo">
        <span> {{ $t('protocolo.sobreSolicitacao') }}: </span>
      </div>
      <div class="observacao cinza">
        <span v-html="history.MSG_DESC"></span>
      </div>
    </div>
  </div>
  <div v-else>{{ $t('erro.semDados') }}</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'history',
  props: {},
  computed: {
    ...mapGetters('protocol_detail', { historic: 'getHistory' }),
    ...mapGetters('protocol_detail', { protocol: 'getProtocol' }),
  },
  methods: {},
  created: function () {},
  watch: {},
  data: () => ({}),
}
</script>

<style lang="scss">
.c-history {
  .historic {
    margin-bottom: var(--space__4-5x);
    border-left: 3px solid var(--color-neutral__07);
    padding-left: var(--space__2x);
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 1024px) {
      padding-left: var(--space);
    }
  }

  b {
    color: var(--theme-black);
  }
  .titulo {
    font-size: 1.2rem;
    .status {
      font-weight: bold;
      border-bottom: 3px solid var(--theme-color);
    }
    margin-bottom: var(--space);
  }
  .subtitulo {
    font-size: 1.1rem;
    font-weight: bold;
  }
  .linha {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.2rem;
    margin-bottom: var(--space);
    > div {
      margin-right: var(--space__5-5x);
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 1024px) {
        margin-bottom: var(--space__0-5x);
        margin-right: var(--space__1-5x);
      }
    }
    .valor {
      font-weight: bold;
      color: var(--theme-success);
    }
    .observacoes {
      max-width: 75%;
    }
    .produto {
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
  .cinza {
    padding: var(--space__1-5x);
    background-color: var(--color-neutral__07);
  }
  hr {
    background-color: var(--color-neutral__07);
  }
  .observacao {
    max-height: 200px;
    overflow-y: scroll;
  }
}
</style>
