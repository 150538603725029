import { createRouter, createWebHistory } from 'vue-router'
import isLogged from '../helpers/sessionHandlers/isLogged'

import About from '../views/About.vue'
import Home from '../views/Home.vue'
import ProductData from '../views/ProductData.vue'
import BuyerData from '../views/BuyerData.vue'
import ConfirmProtocol from '../views/ConfirmProtocol.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Protocols from '../views/Protocols.vue'
import Protocol from '../views/Protocol.vue'
import PorductPictures from '../views/PorductPictures.vue'
import BankData from '../views/BankData.vue'

const sub_routes_create_protocol = [
  {
    path: 'produto',
    component: ProductData,
  },
  {
    path: 'comprador',
    component: BuyerData,
  },
  {
    path: 'fotos',
    component: PorductPictures,
  },
  {
    path: 'banco',
    component: BankData,
  },
  {
    path: 'confirmacao',
    component: ConfirmProtocol,
  },
]

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      freeAccess: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      freeAccess: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/home',
  },
  {
    path: '/protocolos',
    name: 'Protocols',
    component: Protocols,
  },
  {
    path: '/protocol/:prt_id',
    name: 'ProtocolDetail',
    component: Protocol,
  },
  {
    path: '/produtos-adquiridos-online',
    name: 'ProductDataEcommerce',
    children: [
      ...sub_routes_create_protocol.map((route) => {
        route.props = { ecommerce: true }
        return route
      }),
    ],
  },
  {
    path: '/produtos-adquiridos-fora-da-loja-online',
    name: 'ProductDataReclamacao',
    children: [...sub_routes_create_protocol],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach(isLogged)

export default router
