export default {
  status: false,
  options: {
    variant: 'info',
    hideDelay: 5000,
    title: '',
    message: '',
    submessage: '',
    color: '',
    icon: '',
  },
  CloseCallback: () => {},
  OpenCallback: () => {},
}
