import { clientAPI } from '../configs/configs'
import * as env from '../configs/configs'

const urlBase = env.urlGeneral + '/returns'
export default class Orders {
  static async loadOrders() {
    return new Promise((res, rej) => {
      try {
        clientAPI.post(`${urlBase}/orders`).then((response) => {
          res(response.data)
        })
      } catch (err) {
        rej(err)
      }
    })
  }
}
