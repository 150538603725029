import * as types from './mutation-types'
import store from '../..'

export const showToast = async (
  { commit },
  options = { variant: 'info', message: 'TOAST', hideDelay: 3000 }
) => {
  // const toast_types = store.getters['toasts/getToastTypes'] || ''
  return createToast(options).show()
}

export const beginLoading = async () => {
  const is_loading = store.getters['toasts/getIsLoading'] || false
  if (is_loading) return false

  const loading_toast = createLoadingToast()
  if (!loading_toast) return false
  store.dispatch('toasts/setLoadingToast', loading_toast)
  store.dispatch('toasts/setLoadingStatus', true)

  loading_toast.show()
}

export const endLoading = async () => {
  const is_loading = store.getters['toasts/getIsLoading'] || false
  if (!is_loading) return false

  let loading_toast = store.getters['toasts/getLoadingToast'] || false
  if (!loading_toast) return false

  store.dispatch('toasts/setLoadingStatus', false)
  store.dispatch('toasts/setLoadingToast', {})

  loading_toast.hide()
}

export const showError = async (message, variant = 'error') => {
  return createToast({
    variant,
    message,
  }).show()
}

function createToast(toast_options) {
  const $toast = store.getters['toasts/getOriginalPlugin'] || {}
  const default_options = {
    variant: 'info',
    message: 'TOAST',
    allowClose: true,
    hideDelay: 5000,
  }
  const options = Object.assign({}, default_options, toast_options)
  let toast = $toast[options.variant](options.message, options)

  toast.hide = function () {
    this.unmount()
  }
  toast.show = function () {
    const toastWrapper = document.createElement('div')
    this.mount(toastWrapper)
    document.body.appendChild(toastWrapper)
    return this
  }
  return toast.show()
}

export const config = async ({ commit }, $toast) => {
  commit(types.SET_ORIGINAL_PLUGIN, { $toast })
}

const createLoadingToast = () => {
  let loading_toast = store.getters['toasts/getLoadingToast'] || {}
  const loading_message = store.getters['toasts/getLoadingMessage'] || ''

  if (loading_toast.hasOwnProperty('_uid')) return loading_toast

  const options = {
    variant: 'loading',
    autoHide: false,
    message: loading_message,
  }
  loading_toast = createToast(options)

  return loading_toast
}

export const setLoadingMsg = async ({ commit }, message) => {
  commit(types.SET_LOADING_TOAST_MSG, { message })
}

export const setLoadingStatus = async ({ commit }, status) => {
  commit(types.SET_LOADING_STATUS, { status })
}

export const setLoadingToast = async ({ commit }, loading_toast) => {
  commit(types.SET_LOADING_TOAST, { loading_toast })
}
