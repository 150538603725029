import * as types from './mutation-types'

export default {
  [types.SET_SUBJECTS](state, payload = { subjects }) {
    state.data.subjects = payload.subjects
  },
  [types.SET_REASONS](state, payload = { reasons }) {
    state.data.reasons = payload.reasons
  },
  [types.SET_SUB_REASONS](state, payload = { subReasons }) {
    state.data.subReasons = payload.subReasons
  },
  [types.SET_ALL_SUB_REASONS](state, payload = { subReasons }) {
    state.data.allSubReasons = payload.subReasons
  },
  [types.SET_DATA_CONSUMERS](state, payload = { consumers }) {
    state.dataConsumer = payload.consumers
  },
  [types.FILL_DADOS_GERAIS](state, payload = { dadosGerais }) {
    state.dadosGerais = payload.dadosGerais
  },
  [types.FILL_DADOS_ITENS](state, payload = { dadosItens }) {
    state.dadosItens = payload.dadosItens
  },
  [types.SET_CURRENT_STEP](state, payload = { step_num }) {
    state.current_step = payload.step_num
  },
  [types.SET_PICTURE_LIST](state, payload = { picture_list }) {
    state.picture_list = payload.picture_list
  },
  [types.SET_ITENS_TO_ADD_BANK_DATA](state, payload = { itensToAddBankData }) {
    state.itensToAddBankData = payload.itensToAddBankData
  },  
  [types.FILL_BANK_DATA](state, payload = { bankData }) {
    state.bankData = payload.bankData
  }
}
