<template>
  <customer-view-template>
    <stepper />
    <div class="product-pictures">
      <form action="" @submit="formSubmit">
        <div class="form-inline">
          <h3>{{ $t('etapas.fotos.titulo') }}</h3>
          <div>
            <dass-send-file
              @input="attachPicture"                           
              :multiple="true"
            >
              <template v-slot:label-instructions>
                {{ $t('etapas.fotos.arraste') }}
              </template>
              <template v-slot:label-separator>{{
                $t('etapas.fotos.ou')
              }}</template>
              <template  v-slot:upload-button>               
                {{ $t('etapas.fotos.anexar') }}
                <dass-icon token="icon-file-attachment" size="25" />
              </template>
            </dass-send-file>
          </div>
          <div class="preview">
            <div
              class="picture-container"
              v-for="(picture, index) in picture_list"
              v-bind:key="index"
            >
              <div class="actions">
                <a
                  class="action"
                  @click="removePicture(picture, index)"
                  :title="$t('etapas.fotos.remover')"
                >
                  <dass-icon token="icon-action-delete_outline" size="30" />
                </a>
              </div>
              <div class="picture">
                <img v-if="picture.file_type==='image'" :src="picture.object_url" alt="" />
                <object v-if="picture.file_type==='file'" :data="picture.object_url" :type="picture.type">
                  <img alt="" :src="requireImage(`files/file.png`)" width="158" height="158"/>
                </object>
              </div>
            </div>
          </div>
        </div>

        <div class="actions">
          <dass-button
            :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"
            :id="`dass-button-tertiary-close-${this.brandName}`"
            variant="tertiary"
            type="button"
            @click="cancel"
          >
            <dass-icon token="icon-content-clear" size="30"></dass-icon>
            {{ $t('dass.cancelar') }}
          </dass-button>

          <dass-button :class="this.isSafari ? `dass-button-primary-${this.brandName} button` : 'button'" variant="primary" type="submit">
            {{ $t('dass.proximo') }}
            <dass-icon
              token="icon-navigation-arrow_forward_ios"
              size="30"
            ></dass-icon>
          </dass-button>
        </div>
      </form>
    </div>
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import Stepper from '../components/organisms/customer_view_template/Stepper'
import { mapGetters, mapActions } from 'vuex'
import requireImage from '../helpers/functions/requireImage'
import { typesPayment } from '../helpers/constants/typesPayment'
const refund = 'Estorno'

export default {
  name: 'Home',
  components: { CustomerViewTemplate, Stepper },
  computed: {
    ...mapGetters('brand', { brand: 'getBrand' }),
    ...mapGetters('create_protocol', {
      dados_item: 'getDadosItens',
      getPictureList: 'getPictureList'
    }),
  },
  methods: {
    ...mapActions('create_protocol', ['setCurrentStep', 'setPictureList']),
    ...mapActions('toasts', ['showToast']),
    requireImage,
    formSubmit: function (e) {
      e.preventDefault()
      let picture_list = this.picture_list
      if (picture_list.length == 0) {
        return this.showToast({
          variant: 'warning',
          message: this.$t('etapas.fotos.erroQTDFotos'),
        })
      }
      this.setPictureList(picture_list)
      return this.next()
    },
    attachPicture: function (e) {
      if (e.hasOwnProperty('isTrusted')) return
      Array.from(e).forEach((picture) => {
        if (this.picture_list.length >= this.picture_qtd) return
        let fileType = ''
        if(['image'].includes(picture.type.substring(0, picture.type.indexOf('/')) ))
          fileType='image'
        else
          fileType='file'

        let temp = {
          original_file: picture,
          object_url: URL.createObjectURL(picture),
          file_type: fileType,
          type: picture.type
        }
        this.picture_list.push(temp)        
      })
    },
    removePicture: function (picture, index) {      
      this.picture_list.splice(index, 1)      
    },
    next: function () {        
      if(!Array.isArray(this.dados_item)) {          
        if(this.dados_item.desejoNome.includes(refund) && this.dados_item.pedTipoPgto == typesPayment.BOLETO){             
          const itemWithRefund = this.dados_item                     
          return this.$router.push({
            path: '/produtos-adquiridos-online/banco',
          })
        }
      }else if(this.dados_item.some(item => item.desejoNome.includes(refund) && item.pedTipoPgto == typesPayment.BOLETO)) {             
        const itemWithRefund = this.dados_item.filter((item) => {
          return item.desejoNome.includes(refund)
        })        
        this.setItensToAddBankData(itemWithRefund)        
        return this.$router.push({
          path: '/produtos-adquiridos-online/banco',
        })            
      }
      this.$router.push({
        path: '/produtos-adquiridos-fora-da-loja-online/confirmacao',
      })
    },
    cancel: function () {
      this.$router.go(-1)
    },
    setCssForSafari() {
      const buttonFile = document.querySelector('.dass-send-file--upload-button')    
      switch (this.brandName) {
      case 'fila':
        buttonFile.classList.add('dass-button-primary-fila')
        break
      case 'umbro':
        buttonFile.classList.add('dass-button-primary-umbro')  
        break
      case 'newbalance':
        buttonFile.classList.add('dass-button-primary-newbalance')
        break
      default:
        break
      }
    }
  },
  created: function () {
    this.isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)
    this.brandName = this.brand.name.replace(/\s/g, '').toLowerCase()
    this.setCurrentStep(3)
  },
  mounted: function () {
    if(this.isSafari) {
      this.setCssForSafari()  
    }    
    if(this.getPictureList.length > 0) {
      this.picture_list = this.getPictureList
    }
  },
  data: () => ({
    form_data: {},
    picture_list: [],
    picture_qtd: 5,
    isSafari: false,
    brandName: '',
  }),
}
</script>

<style lang="scss" scoped>
.product-pictures {
  text-align: left;  
  form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .form-inline {
      width: 100%;
    }

    .preview {
      flex-wrap: wrap;
      display: flex;
      margin-top: var(--space);

      @media (max-width: 576px) {
        justify-content: space-around;
      }
      .picture-container {
        background: var(--color-neutral__03);
        box-shadow: var(--shadow--sm) var(--color-neutral__30);
        display: flex;
        align-items: center;
        position: relative;

        @media (min-width: 576px) {
          &:not(:last-of-type) {
            margin-right: var(--space);
          }
        }
        @media (max-width: 576px) {
          width: 46%;
          margin-bottom: var(--space);
        }
        .actions {
          position: absolute;
          right: var(--space__0-5x);
          top: var(--space__0-5x);
          display: flex;
          flex-direction: column;
          width: 35px;

          .action {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 35px;
            height: 35px;

            width: 100%;
            cursor: pointer;
            background: var(--color-neutral__03);
            box-shadow: var(--shadow--sm) var(--color-neutral__30);
            border-radius: var(--border-radius-md);
            &:hover {
              background: var(--color-neutral__07);
            }
            &:not(:last-of-type) {
              margin-bottom: var(--space__0-5x);
            }
          }
        }
        .picture {
          height: auto;
          max-width: 250px;
          max-height: 250px;
          display: flex;

          > img, object {
            width: 100%;
            height: auto;
            border-radius: var(--border-radius-md);
          }
        }
      }
    }

    .actions {
      > button:first-of-type {
        margin-right: var(--space);
      }
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
    }
  }
  #dass-button-tertiary-close-umbro {
    color: var(--theme-color) !important;    
  }
}
</style>
