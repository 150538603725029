export default {
  language: {
    current: {
      flag: 'br',
      lang: 'pt',
      locale: 'pt-br',
    },
    possibilities: [
      { flag: 'br', lang: 'pt', locale: 'pt-br' },
      { flag: 'us', lang: 'en', locale: 'en' },
      { flag: 'es', lang: 'es', locale: 'es' },
    ],
  },
}
