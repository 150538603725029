export const BRANDS = {
  NEW_BALANCE: {
    name: 'New Balance',
    color: '#CF0A2C',
    id: 4,
    folder: 'new_balance',
    class_name: 'new_balance',
    mar_cods: [157],
  },
  FILA: {
    name: 'Fila',
    color: '#19191A',
    id: 2,
    folder: 'fila',
    class_name: 'fila',
    mar_cods: [9, 38],
  },
  UMBRO: {
    name: 'Umbro',
    color: '#000000',
    id: 1,
    folder: 'umbro',
    class_name: 'umbro',
    mar_cods: [1],
  },
}
