export default {
  original_plugin: {},
  active_toasts: {},
  loading_toast: {
    message: '',
    toast: {},
    active: false,
  },
  toast_types: {
    error: 'error',
    info: 'info',
    loading: 'loading',
    success: 'success',
    warning: 'warning',
  },
}
