<template>
  <div class="alert" :style="css_vars">
    <dass-alert
      v-if="is_alert_open"
      ref="alert"
      :unclosable="false"
      :closeLabel="'close'"
      :variant="'success'"
      :title="' '"
      @close="
        () => {
          close()
        }
      "
    >
      <template #default>
        <div class="icon" v-if="handdler_icon">
          <dass-icon :token="handdler_icon" size="35" />
        </div>
        <div class="conteudo">
          <div class="titulo" v-if="options.hasOwnProperty('title')">
            {{ handdler_title || '' }}
          </div>
          <div class="texto" v-if="options.hasOwnProperty('message')">
            <span v-html="options.message || ''" />
          </div>
          <div class="subtexto" v-if="options.hasOwnProperty('submessage')">
            <span v-html="options.submessage || ''" />
          </div>
        </div>
        <div class="footer-bar"></div>
      </template>
    </dass-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Alert',
  props: {},
  computed: {
    ...mapGetters('alert', {
      is_alert_open: 'getAlertStatus',
      options: 'getOptions',
      OpenCallback: 'getOpenCallback',
      CloseCallback: 'getCloseCallback',
    }),
    alert_component: function () {
      return this.$refs.alert
    },
    css_vars: function () {
      return `${this.css_hide_delay} ${this.css_color}`
    },
    css_hide_delay: function () {
      return `--alert-progress-duration: ${this.handdler_hideDelay}ms;`
    },
    css_color: function () {
      const variant = this.options.variant || 'info'
      if (this.options.hasOwnProperty('color') && this.options.color != '')
        return this.options.color

      let color = this.colors.info
      if (this.colors.hasOwnProperty(variant)) color = this.colors[variant]
      return `color: ${color};`
    },
    handdler_icon: function () {
      const variant = this.options.variant || 'info'
      if (this.options.hasOwnProperty('icon') && this.options.icon != '')
        return this.options.icon

      let icon = this.icons.info
      if (this.icons.hasOwnProperty(variant)) icon = this.icons[variant]
      return icon
    },
    handdler_hideDelay: function () {
      let hideDelay = this.defaultHideDelay
      if (
        this.options.hasOwnProperty('hideDelay') &&
        this.options.hideDelay != ''
      )
        return this.options.hideDelay
      return hideDelay
    },
    handdler_title: function () {
      let title = this.options.title || ''
      const variant = this.options.variant || 'info'
      if (!title || title == '') {
        title = this.$t(`alerts.titulo.${variant}`)
      }

      return title
    },
  },
  methods: {
    ...mapActions('alert', ['toggleAlert', 'resetAlert']),
    close: function () {
      this.toggleAlert(false)
      clearTimeout(this.hide_queue)
      this.resetAlert()
      this.CloseCallback()
      if (this.alert_component) return this.alert_component.leave()
    },
  },
  watch: {
    is_alert_open: function (new_val, old_val) {
      if (new_val === old_val) return
      // opening
      if (!old_val && new_val) {
        this.OpenCallback()
        this.hide_queue = setTimeout(() => {
          this.close()
        }, this.handdler_hideDelay + 100)
      }
    },
  },
  created: function () {
    this.OpenCallback()
    this.hide_queue = setTimeout(() => {
      this.close()
    }, this.handdler_hideDelay + 100)
  },
  data: () => ({
    defaultHideDelay: 5000,
    hide_queue: {},
    variant: 'info',
    icons: {
      success: 'icon-action-check_circle',
      error: 'icon-alert-error-outline',
      warning: 'icon-alert-warning-amber',
      info: 'icon-action-info',
    },
    colors: {
      success: 'var(--color-status--success)',
      error: 'var(--color-status--error)',
      warning: 'var(--color-status--warning)',
      info: 'var(--color-status--info)',
    },
  }),
}
</script>

<style lang="scss">
@keyframes alert-progress {
  to {
    width: 0;
  }
}
.dass-alert--control {
  padding: var(--space__2x) var(--space__1-5x) !important;
  position: relative;
  border-radius: 0.9rem;
  overflow: hidden;
  padding-right: var(--space__2x) !important;
  max-width: 450px !important;
  @media (max-width: 1024px) {
    width: 95%;
    max-width: 95%;
  }

  > .dass-icon {
    display: none;
  }
  .dass-alert--header {
    position: absolute;
    right: 0;
    top: 0;
    .dass-alert--title {
      display: none;
    }
  }
  .dass-alert--close-button {
    background: transparent !important;

    &:hover {
      color: var(--color-neutral__70);
    }
    &:focus {
      outline: none;
      color: var(--color-neutral__50);
    }
  }
  .dass-alert--content {
    display: flex;
    width: 100%;
    .icon {
      margin-right: var(--space);
      @media (max-width: 1024px) {
        margin-right: var(--space__0-75x);
      }
    }
    .conteudo {
      .titulo {
        font-weight: bold;
        font-size: 1.2rem;
      }
      .texto,
      .subtexto {
        color: var(--theme-black);
        line-height: 1.1rem;
        margin-top: var(--space__0-5x);
      }
    }
  }
  .footer-bar {
    height: 5px;
    background: currentColor;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    animation: alert-progress var(--alert-progress-duration) linear forwards;
  }
}
</style>
