import * as types from './mutation-types'

export default {
  [types.TOGGLE_ALERT](state, payload = { status }) {
    state.status = payload.status
  },
  [types.SET_OPTIONS](state, payload = { options }) {
    state.options = payload.options
  },
  [types.SET_CLOSE_CALLBACK](state, payload = { CloseCallback }) {
    state.CloseCallback = payload.CloseCallback
  },
  [types.SET_OPEN_CALLBACK](state, payload = { OpenCallback }) {
    state.OpenCallback = payload.OpenCallback
  },
}
