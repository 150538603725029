import Orders from '@/services/orders'
import * as types from './mutation-types'
import store from '../..'

export const loadOrders = async ({ commit }) => {
  const brand = store.getters['brand/getBrand'] || {}

  const response = await Orders.loadOrders()
  let orders = response.productBalance || []
  const mar_cods = brand.mar_cods || []
  orders = orders.filter((order) => {
    return mar_cods.includes(order.MAR_CODIGO)
  })

  orders = orders.map((order) => {
    let unique_key = generateUID()
    if (order.hasOwnProperty('FAT_SEQUENCIA')) {
      if (order.hasOwnProperty('ITE_SEQUENCIA')) {
        unique_key = `${order.FAT_SEQUENCIA}_${order.ITE_SEQUENCIA}_${unique_key}`
      }
    }
    order.UNIQUE_KEY = unique_key
    return order
  })
  commit(types.FILL_ORDERS, { orders })
}

export const selectOrder = async ({ commit }, order) => {
  const KEY = 'UNIQUE_KEY'
  if (!order.hasOwnProperty(KEY)) return

  const selected_orders_store = store.getters['orders/getSelectedOrders'] || {}
  const ORDER_KEY = order[KEY] || ''

  let selected_orders = Object.assign({}, selected_orders_store)
  selected_orders[ORDER_KEY] = order

  commit(types.FILL_SELECTED_ORDERS, { selected_orders })
}

export const unselectOrder = async ({ commit }, order) => {
  const KEY = 'UNIQUE_KEY'
  if (!order.hasOwnProperty(KEY)) return

  const selected_orders_store = store.getters['orders/getSelectedOrders'] || {}
  const ORDER_KEY = order[KEY] || ''

  if (!selected_orders_store.hasOwnProperty(ORDER_KEY)) return

  let selected_orders = Object.assign({}, selected_orders_store)
  delete selected_orders[ORDER_KEY]

  commit(types.FILL_SELECTED_ORDERS, { selected_orders })
}

export const clearSelectedOrders = async ({ commit }) => {
  const selected_orders = {}
  commit(types.FILL_SELECTED_ORDERS, { selected_orders })
}

export const toggleSidebar = async ({ commit }, opened) => {
  const opened_store = store.getters['orders/getSidebarOpened'] || false

  if (typeof opened == 'undefined') opened = !opened_store
  commit(types.TOGGLE_SIDEBAR, { sidebar_opened: opened })
}

const generateUID = function () {
  let rnd = Math.random() * (10000 - 1) + 1
  rnd = rnd + ''
  rnd = rnd.replace('.', '')
  let uid = `${rnd}`
  return uid
}
