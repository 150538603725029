import { createStore } from 'vuex'
import toasts from './modules/toasts'
import session from './modules/session'
import modals from './modules/modals'
import create_protocol from './modules/create_protocol'
import protocols from './modules/protocols'
import protocol_detail from './modules/protocol_detail'
import orders from './modules/orders'
import brand from './modules/brand'
import language from './modules/language'
import alert from './modules/alert'
import addressBook from './modules/addressBook'

export default createStore({
  modules: {
    toasts,
    session,
    modals,
    create_protocol,
    protocols,
    protocol_detail,
    orders,
    brand,
    language,
    alert,
    addressBook,
  },
})
