<template>
  <div class="language-selector">
    <dass-language-selector
      :options="locales"
      v-model="language"
      :locale="language"
      @change:lang="handdleChange"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'language-selector',
  components: {},
  computed: {
    ...mapGetters('language', {
      locales: 'getPossibilities',
      language: 'getLanguage',
    }),
  },
  methods: {
    ...mapActions('language', ['setLanguage']),

    handdleChange(lang) {
      let current_lang = this.language.lang || ''
      if (lang == current_lang) return
      this.setLanguage({ lang })
    },
  },
  created: async function () {
    const lang =
      sessionStorage.getItem('lang') || navigator.language.substring(0, 2) || ''
    this.setLanguage({ lang })
  },
  watch: {},
  data: () => ({}),
}
</script>

<style lang="scss">
.dass-language-selector {
  box-shadow: none;
  ul.dass-language-selector--menu {
    padding-bottom: 0;
  }
  .dass-icon-navigation-expand_more {
    color: black;
  }
}

.theme-umbro {
  .language-selector {
    --theme-white: var(--theme-color);
    --color-neutral__00: var(--theme-color);
    --color-neutral__07: var(--color-neutral__90);
  }
  .dass-language-selector {
    background-color: var(--theme-color) !important;
    box-shadow: none;

    ul.dass-language-selector--menu {
      filter: none;
    }
    .dass-icon-navigation-expand_more {
      color: #fff !important;
    }
  }
}
</style>
