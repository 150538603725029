import Address from '@/services/addressBook'
import * as types from './mutation-types'
import store from '../..'

export const getDataCeps = async ({ commit }, ceps) => { 
  let cepData = await Address.getDataCeps(ceps)
  cepData = cepData.ceps || []
  commit(types.GET_DATA_CEPS, { cepData })

  return cepData
}