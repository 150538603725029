import * as types from './mutation-types'

export default {
  [types.TOOGLE_MODAL_QUICK_ACCESS](state, payload = { modal_status }) {
    state.modal_quick_access.active = payload.modal_status
  },
  [types.TOOGLE_MODAL_VERIFY_CODE](state, payload = { modal_status }) {
    state.modal_verify_code.active = payload.modal_status
  },
}
