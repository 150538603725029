import * as types from './mutation-types'
import store from '../..'

export const toggleAlert = async ({ commit }, status) => {
  commit(types.TOGGLE_ALERT, { status })
}
export const setOptions = async ({ commit }, alert_options) => {
  const default_options = {
    variant: 'info',
    hideDelay: 5000,
    title: '',
    message: '',
    submessage: '',
    color: '',
    icon: '',
    CloseCallback: () => {},
    OpenCallback: () => {},
  }
  let options = {}
  if (typeof alert_options == 'string') {
    options = { title: alert_options }
  }

  options = Object.assign({}, default_options, alert_options)
  commit(types.SET_OPTIONS, { options })

  const OpenCallback = options.OpenCallback
  const CloseCallback = options.CloseCallback
  commit(types.SET_OPEN_CALLBACK, { OpenCallback })
  commit(types.SET_CLOSE_CALLBACK, { CloseCallback })
}

export const resetAlert = function ({ commit }) {
  const options = {
    variant: 'info',
    hideDelay: 5000,
    title: '',
    message: '',
    submessage: '',
    color: '',
    icon: '',
    CloseCallback: () => {},
    OpenCallback: () => {},
  }
  commit(types.SET_OPTIONS, { options })

  const OpenCallback = options.OpenCallback
  const CloseCallback = options.CloseCallback
  commit(types.SET_OPEN_CALLBACK, { OpenCallback })
  commit(types.SET_CLOSE_CALLBACK, { CloseCallback })
}

export const showAlert = function (alert_options) {
  store.dispatch('alert/setOptions', alert_options)
  store.dispatch('alert/toggleAlert', true)
}
