<template>
  <div class="resumed-order">
    <div class="remove">
      <dass-link @click="unselectOrder(order)">
        <span>{{ $t('dass.cancelar') }}</span>
      </dass-link>
    </div>
    <div class="thumbnail">
      <img :src="order.thumbnail" :alt="order.produtoNome" />
    </div>
    <div class="titulo">
      <div class="nome">
        <b>{{ order.produtoNome }}</b>
      </div>
      <div class="desejo">
        <div>
          <b>{{ $t('etapas.produto.desejo') }}: </b>
        </div>
        <span>{{ order.desejoNome }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Order',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('orders', ['toggleSidebar', 'unselectOrder']),
  },
}
</script>

<style lang="scss">
.resumed-order {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  line-height: 1rem;
  font-size: 0.9rem;
  padding: var(--space__1-5x) 0;
  &:not(:last-of-type) {
    border-bottom: 2px solid var(--theme-gray_light);
  }
  
  position: relative;
  .remove {
    margin: var(--space__0-5x) 0;
    position: absolute;
    top: 0;
    right: 0;   
    background-color: red;
    span {
      font-weight: bold;
      color: var(--color-neutral__00) !important;
    }
  }
  .thumbnail {
    width: 30%;
    padding-right: var(--space__0-5x);
    img {
      width: 100%;
      height: auto;
    }
  }
  .titulo {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: column;
    > div {
      margin-bottom: var(--space__0-5x);
    }
  }
}
</style>
