import Protocols from '@/services/protocols'
import store from '../..'
import * as types from './mutation-types'

export const loadProtocols = async ({ commit }, filters) => {
  const brand = store.getters['brand/getBrand'] || {}
  const mar_id = brand.id || ''
  const default_filters = {
    mar_id,
  }

  const request_body = Object.assign({}, default_filters, filters)
  const response = await Protocols.getProtocols(request_body)
  let protocols = response.calls || []
  protocols = processDetail(protocols)

  commit(types.FILL_PROTOCOLS, { protocols })
  return protocols
}

export const processDetail = (protocols) => {
  let result = []
  result = protocols.map((protocol) => {
    return protocol
  })
  return result
}
