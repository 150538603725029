<template>
  <customer-view-template>
    <div
      class="protocol-detail"
      v-if="protocol && protocol.hasOwnProperty('PRT_ID')"
    >
      <div class="history">
        <history></history>
      </div>
    </div>
    <div v-else>
      {{ $t('erro.carregar') }}
    </div>
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import History from '../components/organisms/protocol/History'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Protocol',
  components: { CustomerViewTemplate, History },
  computed: {
    prt_id: function () {
      return this.$route.params.prt_id
    },
    ...mapGetters('protocol_detail', { protocol: 'getProtocol' }),
  },
  methods: {
    ...mapActions('protocol_detail', ['loadProtocol', 'loadProtocolHistory']),
    loadData: async function () {
      let response_protocol = await this.loadProtocol({ prt_id: this.prt_id })
      let response_history = await this.loadProtocolHistory({
        prt_id: this.prt_id,
      })
    },
  },
  created: function () {    
    this.loadData()
  },
}
</script>

<style lang="scss">
.protocol-detail {
  .history {
    width: 60%;
    @media (max-width: 1200px) {
      width: 100%;
      margin-bottom: var(--space);
    }
  }
}
</style>
