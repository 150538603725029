<template>
  <div :class="`theme-${brand.class_name || ''}`">
    <router-view />
    <alert />
  </div>
</template>

<script>
import './styles/reset.css'
import './styles/forms.scss'
import './styles/situacoes_colors.scss'
import './styles/styleSafari.scss'
import './styles/theme/theme.scss'

import { mapGetters, mapActions } from 'vuex'
import Alert from './components/molecules/Alert.vue'
export default {
  name: 'App',
  components: { Alert },
  methods: {
    ...mapActions('toasts', {
      configToastPlugin: 'config',
      showToast: 'showToast',
      setLoadingMsg: 'setLoadingMsg',
    }),
    ...mapActions('brand', ['switchBrand']),
  },
  created: async function () {
    this.setLoadingMsg(this.$t('menssagens.carregandoDados'))
    this.configToastPlugin(this.$toast)

    let current_url = window.location.href
    this.switchBrand(current_url)

    const rootCSS = document.querySelector(':root body')
    rootCSS.style.setProperty('--theme-color', this.brand.color)
  },
  computed: {
    ...mapGetters('brand', { brand: 'getBrand' }),
  },
}
</script>

<style>
#app {
  font-family: ProximaNova;
}
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--theme-color) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  border-radius: 10px;
  border: 3px solid #ffffff;
}
</style>
