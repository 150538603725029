<template>
  <div></div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',
  methods: {
    ...mapActions('session', ['doLogout']),
  },
  created: function () {
    this.doLogout()
    this.$router.push({
      path: '/login',
    })
  },
}
</script>

<style lang="scss"></style>
