import { cpfValidator } from './cpfValidator'
import { emailValidator } from './emailValidator'
import { cnpjValidator } from './cnpjValidator'
import { telefoneValidator } from './telefoneValidator'

export const pixValidator = function (key) { 
  const pix = key.trim() 
  return cpfValidator(pix) || cnpjValidator(pix) || emailValidator(pix) || telefoneValidator(pix)
  
}
