export const isJson = function (str) {
  let obj = {}
  try {
    obj = JSON.parse(str)
  } catch (e) {
    return false
  }
  return obj
}
export default isJson
