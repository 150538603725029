import * as types from './mutation-types'

export const toogleModalQuickAccess = async ({ commit }, modal_status) => {
  commit(types.TOOGLE_MODAL_QUICK_ACCESS, { modal_status })
  return modal_status
}

export const toogleModalVerifyCode = async ({ commit }, modal_status) => {
  commit(types.TOOGLE_MODAL_VERIFY_CODE, { modal_status })
  return modal_status
}
