import * as types from './mutation-types'

export default {
  [types.ADD_ACTIVE_TOAST](state, payload = { uuid, toast }) {
    state.active_toasts[payload.uuid] = payload.toast
  },
  [types.REMOVE_ACTIVE_TOAST](state, payload = { uuid }) {
    delete state.active_toasts[uuid]
  },
  [types.SET_LOADING_TOAST](state, payload = { loading_toast }) {
    state.loading_toast.toast = payload.loading_toast
  },
  [types.SET_LOADING_STATUS](state, payload = { status }) {
    state.loading_toast.active = payload.status
  },
  [types.SET_LOADING_TOAST_MSG](state, payload = { message }) {
    state.loading_toast.message = payload.message
  },
  [types.SET_ORIGINAL_PLUGIN](state, payload = { $toast }) {
    state.original_plugin = payload.$toast
  },
}
