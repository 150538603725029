import moment from '../date/moment'

export const toDateBRL = (value, fromFormat = false) => {
  if (!value) return ''
  if (typeof value == 'undefined') return ''

  let newDate = moment(value)
  if (fromFormat !== false) newDate = moment(value, fromFormat)

  newDate = newDate.tz('Etc/UCT')
  newDate = newDate.format('DD/MM/YYYY')
  if (newDate == 'Invalid date') return ''

  return newDate
}

export const toHourBRL = (value, fromFormat = false) => {
  if (!value) return ''
  if (typeof value == 'undefined') return ''

  let newDate = moment(value)
  if (fromFormat !== false) newDate = moment(value, fromFormat)

  newDate = newDate.tz('Etc/UCT')
  newDate = newDate.format('HH:mm')
  if (newDate == 'Invalid date') return ''

  return newDate
}

export const toDayOfWeek = (value, fromFormat = false) => {
  if (!value) return ''
  if (typeof value == 'undefined') return ''

  let newDate = moment(value)
  if (fromFormat !== false) newDate = moment(value, fromFormat)

  newDate = newDate.tz('Etc/UCT')
  newDate = newDate.format('dddd')
  if (newDate == 'Invalid date') return ''

  return newDate
}

export const validateDate = (date) => {
  const regexDate = /^\d{2}\/\d{2}\/\d{4}$/
  const currentDate = new Date()
  const values = date.split('/')
  const day = parseInt(values[0],10)
  const month = parseInt(values[1], 10)
  const year = parseInt(values[2],  10)
  const lastDayOfMonth  = new Date(year, month, 0).getDate()
 

  if(!regexDate.test(date)) return false  
 
  if(year < 1900 || year > currentDate.getFullYear()) return false

  if(month < 1 ||  month > 12) return false

  if(day < 1 ||  day > lastDayOfMonth) return false

  return true
}

export default {
  toDateBRL,
  toHourBRL,
  toDayOfWeek,
  validateDate
}
