import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './routers'
import i18n from './i18n'
import dassDS from 'ds-ui'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import globalFiltersPlugin from './helpers/globalfilters/globalfilters'
import alertPlugin from './helpers/components/Alert'

export const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(dassDS)
  .use(VueTheMask)
  .use(money)
  .use(globalFiltersPlugin)
  .use(alertPlugin)
  .mount('#app')
