
const types = Object.freeze({
  object:'object',
  number:'number',
  string:'string',
})

const removeFields = (payload) => {
  const result = new Map()
  for (const key in payload) {
    const value = payload[key]
    if(isValidData(value)){
      result.set(key,value)
    }
  }
  return Object.fromEntries(result)
}

const isValidData = (value) => {
  let result = false
  switch (true) {
  case Array.isArray(value):
    result = value.length > 0
    break

  case typeof value === types.object:
    result = Object.keys(value).length > 0
    break

  case typeof value === types.number:
    result = true
    break

  case typeof value === types.string && value !== '':
    result = true
    break
  
  default:
    result = !!value
    break
  }
  return result
}


export function removeEmptyFields(payload){
  let result = null
  switch (true) {
  case Array.isArray(payload):
    result = []
    for (const item of payload) {
      const removedFields = removeFields(item)
      result.push(removedFields)
    }
    break

  case typeof payload === 'object':
    const removedFields = removeFields(payload)
    result = removedFields
    break
  
  default:
    throw new Error('Payload must being an Array or an Object.')
  }
  return result
}